<template>
    <div class="col-lg-12" style="padding: 30px;">
        <p class="text-center" style="color: #00979e;font-size: 25px;display:initial;"></p>
        <div>Point of Care DSS Charts
            <div :data-type="anatomy - illustrations" class="go-to-page go-to-anatomy" title="Anatomy"></div>
            <div class="go-to-calc" title="Calculator"></div>
            <hr class="p-care">
            <span class="chart-hd">Please click a speciality to view its relevant charts</span>
        </div>
        <div>Anatomy Illustrations to Explain to Patients
            <div :data-type="dss - chart" class="go-to-page go-to-dss" title="Dss Chart"></div>
            <div class="go-to-calc" title="Calculator"></div>
            <hr class="p-care">
            <span class="chart-hd" @click="pointofCareSupport()">Anatomy Illustrations to Explain to Patients</span>
        </div>
        <div class="row inner-container" id="popup-inner-container" :key="folders && folders.length">
            <div class="page-header">
                <ul class="folder-list">
                    <div v-for="(folder, index) in folders" :key=index>
                        <li>
                            <div class="dss-charts" :data-type="tag.rootFolder + folder"></div>
                            <span class="list-items"
                                @click="subFolderLists(folder, index); toogleStatus = !toogleStatus;">{{ folder }}{{ folder.imageUrl }}</span>
                        </li>
                        <div class="dss-files" v-if="toogleStatus && chartIndex === index">
                            <ul>
                                <li @click="onclickDss(subfolder)" v-for="(subfolder, indexi) in subfolders.allKeys"
                                    :key=indexi>
                                    {{ subfolder }}
                                </li>
                                <div class="dss-image" v-if="toogleStatus && chartIndex === index">
                                    <ul>
                                        <li @click="clickDss()" v-for="(main, indexj) in mains.allKeys" :key=indexj>
                                            {{ main }}
                                        </li>
                                    </ul>
                                </div>
                            </ul>
                        </div>
                    </div>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios"

export default {
    data() {
        this.dssChartSubfolderList('ICMR STWs/')
        return {
            tag: [],
            folders: [],
            rootFolder: '',
            toogleStatus: false,
            chartIndex: 0,
            subfolders: {},
            mains: {},
        }
    },
    methods: {
        onclickDss(type) {
            this.chartGalleryPlayer(type);
        },
        subFolderLists(type, index) {
            this.chartIndex = index
            this.dssChartSubfolderList(type).then((result) => {
                this.subfolders = result.data
            })
        },
        clickDss() {
            this.chartGalleryPlayer();
        },
        chartGalleryPlayer(type) {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            var str = type.split("/");
            var rootFolderPath = str[0] + "/";
            type = this.type + type
            axios
                .get(`${BASE_API_URL}/library/chart?prefix=${type}`)
                .then((response) => {
                    this.tag = response.tag
                    this.folders = response.data;
                    this.rootFolder = rootFolderPath;
                })
                .catch(function (error) {
                    console.log(error, 'error');
                })
        },
        pointofCareSupport() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/library/point`)
                .then((response) => {
                    this.folders = response.data.data.allKeys;
                })
                .catch(function (error) {
                    console.log(error?.response);
                })
        },
        dssChartSubfolderList(type) {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            this.type = type
            return axios
                .get(`${BASE_API_URL}/library/dss?mainFolder=${type}`)
                .then((response) => {
                    return response.data
                })
        },
        // knowledgeBase() {
        // const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        //     return axios
        // .get(`${BASE_API_URL}/library/knowledge`)
        //         .then((response) => {
        //             return response.data
        //         })
        // },
        // // eslint-disable-next-line no-unused-vars
        // standardTreatmentGuidelines(type) {
        // const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        //     return axios
        //         .get(`${BASE_API_URL}/library/standard`)
        //         .then((response) => {
        //             return response.data
        //         })
        // }
    },
}

</script>
<style>
span.list-items {
    color: #0398e0;
    text-decoration: underline;
    font-size: 15px;
}

.go-to-anatomy,
.go-to-dss,
.go-to-calc {
    display: block;
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: 40px 40px;
    float: right;
    cursor: pointer;
    position: relative;
    top: -3px;
}

.go-to-calc {
    right: 13px;
}

.go-to-anatomy {
    background-image: url('/rex/assets/images1/anatomy-illus.png');
}

.go-to-dss {
    background-image: url('/rex/assets/images1/dss-chart.png');
}

.go-to-calc {
    background-image: url('/rex/assets/images1/clinical-calc-n.png');
}

.go-to-dss:hover {
    background-image: url('/rex/assets/images1/dss-chart-c.png');
}

.go-to-calc:hover {
    background-image: url('/rex/assets/images1/clinical-calc-c.png');
}

.go-to-anatomy:hover {
    background-image: url('/rex/assets/images1/anatomy-illus-c.png');
}

.chart-hd {
    color: #00979e;
    font-size: 17px;
}

ul.folder-list li {
    padding: 5px;
    cursor: pointer;
    line-height: 20px;
    font-size: 16px;
    color: #333;
}

.page-header {
    margin-top: 0px !important;
}

.t-variation {
    color: black;
}

.t-variation:hover,
t-variation:active {
    color: #00979e;
}

.p-care {
    width: 100%;
    margin-top: 5px;
    color: #00979e;
    border-top: 3px solid #00979e;
}

.border {
    display: inline;
    color: #000;
    width: 70px;
    padding-bottom: 5px;
    border: 1px solid #00979e;
    margin: 222px;
}

.border2 {
    display: inline;
    color: #000;
    margin-left: 75px;
    padding-bottom: 5px;
    padding-top: 0px;
    border: 1px solid #00979e;
}

.line1 {
    border: 1px solid #000;
    margin-top: 3px;
    margin-left: 222px;
    margin-right: 64px;
}

.para-text {
    text-align: center;
    margin-top: -14px;
    margin-left: 160px;
    font-size: 13px;
}

.img-workspace-com {
    border: 2px solid #00979e;
    border-radius: 18px;
    padding: 7px;
    text-align: center;
    margin-top: 25px;
    background-color: white;
    cursor: pointer;
    width: 130px;
    height: 143px;
    padding-top: 13px;
    margin-left: 20px;
}


.dss-chart-img {
    background-image: url('/rex/assets/images1/dss-chart.png');
    display: block;
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 50px 50px;
    margin: 0 auto;
    transition: .2s ease-in-out;
    margin-top: 25px;
}

.img-workspace-com:hover .dss-chart-img {
    background-image: url('/rex/assets/images1/dss-chart-c.png');
}

.clinical-calc-img {
    background-image: url('/rex/assets/images1/clinical-calc-n.png');
    display: block;
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 50px 50px;
    margin: 0 auto;
    transition: transform 6s ease-in-out;

    margin-top: 25px;
}

.img-workspace-com:hover .clinical-calc-img {
    background-image: url('/rex/assets/images1/clinical-calc-c.png');
}

.coming-soon-img {
    background-image: url('/rex/assets/images1/comin-soon.png');
    display: block;
    width: 176px;
    height: 61px;
    background-size: 126px 42px;
    margin-top: -44px;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 999;
    left: 0px;
}


.anatomy-illus-img {
    background-image: url('/rex/assets/images1/anatomy-illus.png');
    display: block;
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 50px 50px;
    margin: 0 auto;
    transition: .2s ease-in-out;
    margin-top: 25px;
}

.img-workspace-com:hover .anatomy-illus-img {
    background-image: url('/rex/assets/images1/anatomy-illus-c.png');
}




.select2-choices {
    min-height: 200px !important;
}

li.select2-search-choice div {
    float: left;
    /* position: relative; */
    padding-right: 5px;
}

.select2-container-multi .select2-search-choice-close {
    /* left: 0px !important; */
    FLOAT: RIGHT;
    /* float: right; */
    position: relative;
}

.select2-search-choice-close {
    top: 0px !important;
}


@media (min-width : 1280px) {
    .line1 {
        border: 1px solid #000;
        margin-top: 3px;
        margin-left: 222px;
        margin-right: 82px;
    }
}


@media (min-width : 1366px) {
    .line1 {
        border: 1px solid #000;
        margin-top: 3px;
        margin-left: 222px;
        margin-right: 121px;
    }


    .border {
        display: inline;
        color: #000;
        width: 70px;
        padding-bottom: 5px;
        border: 1px solid #00979e;
        margin: 222px;
    }

    .border2 {
        display: inline;
        color: #000;
        margin-left: 75px;
        padding-bottom: 5px;
        padding-top: 0px;
        border: 1px solid #00979e;
    }

    .para-text {
        text-align: center;
        margin-top: -14px;
        margin-left: 120px;
        font-size: 13px;
    }
}


@media (min-width : 1440px) {
    .line1 {
        border: 1px solid #000;
        margin-top: 3px;
        margin-left: 222px;
        margin-right: 162px;
    }

    .border {
        display: inline;
        color: #000;
        width: 70px;
        padding-bottom: 5px;
        border: 1px solid #00979e;
        margin: 222px;
    }

    .border2 {
        display: inline;
        color: #000;
        margin-left: 75px;
        padding-bottom: 5px;
        padding-top: 0px;
        border: 1px solid #00979e;
    }

    .para-text {
        text-align: center;
        margin-top: -14px;
        margin-left: 90px;
        font-size: 13px;
    }

}
</style>